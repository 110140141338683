<script>
import VMessages from 'vuetify/lib/components/VMessages/VMessages.js';
import VInput from 'vuetify/lib/components/VInput/VInput.js';
import {getImage} from "@/services/helpers";
import {mapGetters} from "vuex";
const propName = "value", eventName = "input"
export default {
  name: "InputSelectBtn",
  components: { VMessages },
  extends: VInput,
  model: {
    prop: propName,
    event: eventName
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    itemText: {
      type: String,
      default: "content",
    },
    itemValue: {
      type: String,
      default: "optionId",
    },
    [propName]: {
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
      required: true
    },
    multiple: {
      type: Boolean,
    },
    fieldId: {
      type: Number,
      required: true,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    savedValue: {
      required: true,
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
    },
    img: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
    },
    multipleForms: {
      type: Boolean,
    },
    multipleFormsIndex: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
    },
    highlight: {
      type: Boolean
    },
  },
  data: function () {
    return {
      showErrorMessage: false,
      validateCalled: 0,
    }
  },
  computed: {
    ...mapGetters("Task", {
      showResults: "getShowResults",
    }),
    model: {
      get() {
        return this[propName]
      },
      async set(payload) {
        this.showErrorMessage = false
        this.$emit(eventName, {
          fieldId: this.fieldId,
          value: payload
        })
      }
    },
    errorMessage() {
      const rule = `pages.questionnaire.rules.required-select${this.multiple ? '-multiple' : ''}`
      return this.showErrorMessage ? [this.$t(rule)] : []
    }
  },
  created() {
    let value
    if (!!this.savedValue && this.savedValue !== "None") {
      value = this.savedValue
    } else if (this.defaultValue) {
      value = this.defaultValue
    }
    if (value)
      this.model = this.multipleForms ? value[this.multipleFormsIndex] : value
  },
  methods: {
    getImage,
    selectBtn(index) {
      const value = this.options[index][this.itemValue]
      if (this.multiple) {
        if (Array.isArray(this.model)) {
          const index = this.model.indexOf(value)

          let arr = this.model.slice()
          if (index === -1) arr.push(value)
          else arr.splice(index, 1)
          this.model = arr

        } else this.model = [value]
      } else {
        if (this.model === value) this.model = null
        else this.model = value
      }
    },
    isItemSelected(index) {
      const value = this.options[index][this.itemValue]
      if (this.multiple) {
        return Array.isArray(this.model) && this.model.indexOf(value) !== -1
      } else return this.model === value
    },
    checkIfValid() {
      return Array.isArray(this.model) ? this.model.length > 0 : !!this.model
    },
    validate() {
      this.validateCalled++
      if (this.validateCalled===1) return
      if (this.required) {
        const valid = this.checkIfValid()
        if (!valid) this.showErrorMessage = true
        return valid
      }
      return true
    },
    getColor(item, i) {
      if (this.showResults && item['is_true']) return 'success'
      if (item.percentage && i === 0 && this.highlight) {
        const p = parseFloat(item.percentage)
        if (p >= 75) return 'success'
        if (p > 40) return 'error'
        return 'primary'
      }
      return this.isItemSelected(i) ? 'primary' : '#d2d2d7'
    }
  },
}
</script>

<template>
  <div class="wrapper">
    <v-img
      v-if="img"
      :src="getImage(img)"
      contain
      class="mb-4"
    />
    <v-btn
      v-for="(item,i) in options"
      :key="`select-btn-${i}`"
      outlined
      block
      x-large
      depressed
      class="button"
      :color="getColor(item, i)"
      :class="{
        'mb-2': i !== options.length - 1,
        'selected': isItemSelected(i) && !showResults,
        'error-state': showErrorMessage,
        'disabled': disabled || showResults
      }"
      @click="selectBtn(i)"
    >
      {{ item[itemText] || item }}
      <template v-if="item.percentage">
        <b>—{{ item.percentage }}</b>
      </template>
    </v-btn>
    <VMessages
      :value="errorMessage"
      color="error"
      style="margin: 8px 12px"
    />
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  width: 100%;
}
.selected {
  border-color: var(--v-primary-base);
  border-width: 2px;
  color: black;
}
.error-state::v-deep {
  border-color: var(--v-error-base) !important;
  border-width: 2px;
  .v-btn__content {
    color: var(--v-error-base) !important;
  }
}
.button::v-deep {
  border-radius: 10px;
  text-transform: none !important;
  height: 120px !important;
  .v-btn__content {
    color: black;
    width: 100%;
    white-space: pre-line;
    word-break: break-word;
  }
}
.disabled {
  pointer-events: none;
  border-width: 2px;
  background-color: currentColor;
  &::v-deep .v-btn__content {
    color: #ffffff !important;
  }
}
</style>
